import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-currency-select",
  templateUrl: "./currency-select.component.html",
  styleUrls: ["./currency-select.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencySelectComponent),
      multi: true,
    },
  ],
})
export class CurrencySelectComponent implements ControlValueAccessor {
  @Input() showAddOption = false;
  @Input() caption;
  @Input() selectDefault = true;
  @Input() value;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  currencies;
  disabled = false;

  constructor(private httpClient: HttpClient) {
    this.httpClient.get("api/currencies").subscribe((response) => {
      this.currencies = response;
      const defaultCurrencyId = 111; // TODO: Replace hard-coded id
      if (!this.value && this.selectDefault)
        this.valueChange.emit(defaultCurrencyId);
    });
  }

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change(newValue) {
    this.valueChange.emit(newValue);
    this.onModelChange(newValue);
  }

  ngOnInit() {}
}
