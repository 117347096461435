import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-payment-method-select",
  templateUrl: "./payment-method-select.component.html",
  styleUrls: ["./payment-method-select.component.css"],
})
export class PaymentMethodSelectComponent implements OnInit {
  @Input() showAddOption = false;
  @Input() value;
  @Input() caption;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  paymentMethods;

  constructor(private http: HttpClient) {
    this.http.get("api/transactions/payment-methods").subscribe((response) => {
      this.paymentMethods = response;
      if (!this.value) this.valueChange.emit(this.paymentMethods[0].id);
    });
  }

  ngOnInit() {}
}
