import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-choosen-select",
  templateUrl: "./choosen-select.component.html",
  styleUrls: ["./choosen-select.component.css"],
})
export class ChoosenSelectComponent {
  @Input() value;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  @Input() caption;
  @Input() width = "18";
  @Input() grouped = false;
  style = { backgroundColor: "red" };
  open = false;
  focussed = false;
  keywords: string = "";
  hidden = false;
  selectedOption;

  @Input() options = [];

  constructor() {}

  openOptionsBox() {
    this.open = !this.open;
  }

  select(option) {
    this.selectedOption = option;
    this.value = option.id;
    this.open = false;
    this.focussed = false;

    this.valueChange.emit(option.id);
  }

  getSelectedOptionText() {
    return this.selectedOption ? this.selectedOption.name : "";
  }

  clearSelection(event: MouseEvent) {
    this.selectedOption = null;
    this.valueChange.emit(null);
    this.open = false;
    this.keywords = '';
    event.stopPropagation();
  }

  handleBlur(event: FocusEvent) {
    const newFocusedElement = event.relatedTarget as HTMLElement;

    if (newFocusedElement && newFocusedElement.parentElement.className.includes('chosen-search')) {
      return;
    }

    if (newFocusedElement && newFocusedElement.className.includes('active-result')){
      return;
    }

    this.keywords = "";
    this.hidden = true;
    setTimeout(
      function () {
        this.open = false;
        this.focussed = false;
      }.bind(this),
      50
    );
  }

  handleInputFocus(event: FocusEvent) {
    // event.stopPropagation();
    this.open = true;
    this.focussed = true;
    console.log("handleInputFocus");
  }

  filterOptions() {
    if(!this.options) return [];
    if (this.grouped) {
      return  this.options.map(group => ({
        groupName: group.groupName,
        accounts: group.accounts.filter(account =>
          account.name.toLowerCase().includes(this.keywords.toLowerCase())
        )
      })).filter(group => group.accounts.length > 0);
    } else {
      return this.options.filter(option =>
        option.name.toLowerCase().includes(this.keywords.toLowerCase())
      );
    }
  }
}
