import { HttpClient } from "@angular/common/http";
import {
  Component,
  OnInit,
  Input,
  NgModule,
  OnChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-account-select",
  templateUrl: "./account-select.component.html",
  styleUrls: ["./account-select.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountSelectComponent),
      multi: true,
    },
  ],
})
export class AccountSelectComponent implements OnInit, ControlValueAccessor {
  @Input() excludePaymentAccounts = false;
  @Input() showAddOption = true;
  @Input() caption = null;

  @Input() value: number;
  @Output() valueChange: EventEmitter<number> = new EventEmitter();

  accounts: any[];
  date: Date;
  disabled = false;

  constructor(private http: HttpClient) {}

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change(newValue) {
    this.valueChange.emit(newValue);
    this.onModelChange(newValue);
  }

  ngOnInit() {
    this.getChartAccounts();
  }

  getChartAccounts() {
    this.http.get("api/chartaccounts").subscribe((response: any[]) => {
      if (this.excludePaymentAccounts) {
        this.accounts = response.filter((x) => !x.isPaymentAccount);
      } else {
        this.accounts = response;
      }

      this.accounts = this.groupByChartAccounts(this.accounts, "accountType");
      this.accounts.sort((prev: any, next) => {
        if (prev.groupName === "Assets") {
          return -1;
        } else if (prev.groupName === "Liabilities") {
          return 0;
        } else {
          return 1;
        }
      });
    });
  }

  groupByChartAccounts(OurArray, property) {
    return OurArray.reduce(function (accumulator, object) {
      // get the value of our object(age in our case) to use for group    the array as the array key
      const key = object[property];
      // if the current value is similar to the key(age) don't accumulate the transformed array and leave it empty
      if (!accumulator.find((m) => m.groupName === key)) {
        accumulator.push({ groupName: key, accounts: [] });
      }
      // add the value to the array
      accumulator.find((m) => m.groupName === key).accounts.push(object);
      // return the transformed array
      return accumulator;
      // Also we also set the initial value of reduce() to an empty object
    }, []);
  }
}
