import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-obligation-type-select",
  templateUrl: "./obligation-type-select.component.html",
  styleUrls: ["./obligation-type-select.component.css"],
})
export class ObligationTypeSelectComponent implements OnInit {
  @Input() showAddOption = false;
  @Input() value;
  @Input() caption;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  obligationTypes;

  constructor(private httpClient: HttpClient) {
    this.httpClient.get("api/obligationTypes").subscribe((response) => {
      this.obligationTypes = response;
    });
  }

  ngOnInit() {}
}
