import { ToastrService } from "ngx-toastr";
import { ReceiptService } from "./../../../../shared/services/receipt.service";
import { NgForm } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { Component } from "@angular/core";

@Component({
  selector: "app-add-obligation",
  templateUrl: "./add-obligation.component.html",
  styleUrls: ["./add-obligation.component.css"],
})
export class AddObligationComponent {
  loading = false;
  type;
  id;
  addScreen;

  obligationType;
  obligationTypes: any[];

  mujtahidId;
  representativeId;

  constructor(
    private router: Router,
    route: ActivatedRoute,
    private http: HttpClient,
    private receiptService: ReceiptService,
    private toastrService: ToastrService
  ) {
    this.addScreen = route.snapshot.data["addScreen"];
    this.type = route.snapshot.data["type"];
    this.id = route.snapshot.params["id"];
    this.model.transactionTypeId = this.type === "deposit" ? 5 : 6;

    if (this.id) {
      http.get(`api/obligations/${this.id}`).subscribe((res) => {
        this.model = res;
      });
    }

    this.loadBreakupTemplates();
  }

  model: any = {
    id: 0,
    entryNumber: "000000",
    transactionTypeId: null,
    personId: null,
    refPersonId: null,
    dateTime: new Date().toISOString(),
    paymentAccountId: null,
    currencyId: null,
    exchangeRate: 1,
    paymentMethodId: null,
    obligationTypeId: null,
    permissionId: null,
    quantity: 0,
    amount: 0,
    paid: false,
    lines: [],
    memo: null,
  };

  loadBreakupTemplates() {
    this.http.get("api/ObligationTypes/permissions").subscribe((res: any[]) => {
      this.obligationTypes = res;

      if (this.model.id) {
        this.obligationType = this.obligationTypes.find(
          (x) => x.id === this.model.obligationTypeId
        );

        const permission = this.obligationType.permissions.find(
          (x) => x.id === this.model.permissionId
        );

        this.mujtahidId = permission.mujtahidId;
        this.representativeId = permission.representativeId;

        for (const line of this.model.lines) {
          this.updatePercentage(line);
        }
      }
    });
  }

  addLine() {
    this.model.lines.push({
      accountId: null,
      memo: null,
      amount: null,
    });
  }

  getTotalAmount() {
    return this.model.lines.reduce((a, b) => a + b.amount, 0);
  }

  createTransaction(print = false, redirectToList = false) {
    this.loading = true;
    this.http.post("api/transactions/obligations", this.model).subscribe(
      (respone: any) => {
        this.loading = false;
        this.model = respone;
        this.id = this.model.id;

        this.toastrService.success(
          "Entry has been created successfully!",
          "Success"
        );

        this.router.navigate([
          "/accounting/transactions/obligation/" + respone.id,
        ]);

        if (print) {
          this.print();
        }

        if (redirectToList) {
          this.router.navigate(["/accounting/transactions"]);
        }
      },
      () => { },
      () => (this.loading = false)
    );
  }

  updateTransaction(print = false, redirectToList = false) {
    this.loading = true;
    this.http.put("api/transactions/obligations", this.model).subscribe(
      (respone) => {
        this.loading = false;
        this.toastrService.success(
          "Entry has been updated successfully!",
          "Success"
        );
        if (print) {
          this.print();
        }

        if (redirectToList) {
          this.router.navigate(["/accounting/transactions"]);
        }
      },
      () => { },
      () => (this.loading = false)
    );
  }

  save(print = false, redirectToList = false) {
    if (this.addScreen) {
      this.createTransaction(print, redirectToList);
    } else {
      this.updateTransaction(print, redirectToList);
    }
  }

  delete() {
    if (confirm("Are you sure you want to delete this transaction?")) {
      this.http.delete(`api/transactions/${this.id}`).subscribe((res) => {
        console.log("deleted", res);
        this.router.navigate(["/accounting/transactions"]);
        this.toastrService.success(
          "Entry has been deleted successfully!",
          "Success"
        );
      });
    }
  }

  cancel() {
    this.router.navigate(["accounting/transactions"]);
  }

  handleObligationTypeChange() {
    this.obligationType = this.obligationTypes.find(
      (x) => x.id === this.model.obligationTypeId
    );

    this.mujtahidId = null;
    this.representativeId = null;

    this.updateBreakupLines();
  }

  updateBreakupLines() {
    if (
      this.obligationType.requiresMujtahid &&
      (!this.mujtahidId || !this.representativeId)
    ) {
      this.model.permissionId = null;
      this.model.lines = [];
      return;
    }

    const permission = this.obligationType.permissions.find(
      (x) =>
        x.mujtahidId === this.mujtahidId &&
        x.representativeId === this.representativeId
    );

    this.model.permissionId = permission.id;
    this.model.lines = permission.ledgerEntryTemplates.map((x) => {
      return {
        accountId: x.chartAccountId,
        percentage: x.percentage,
        amount: (x.percentage / 100) * this.model.amount,
      };
    });

    this.updateDivisions();
  }

  getMujtahids() {
    const mujtahids = Array.from(
      new Set(this.obligationType.permissions.map((x) => x.mujtahidId))
    ).map((id) => {
      return {
        id: id,
        name: this.obligationType.permissions.find((x) => x.mujtahidId === id)
          .mujtahid,
      };
    });
    return mujtahids;
  }

  getRepresentatives() {
    const representatives = Array.from(
      new Set(
        this.obligationType.permissions
          .filter((x) => x.mujtahidId === this.mujtahidId && x.representativeId)
          .map((x) => x.representativeId)
      )
    ).map((id) => {
      return {
        id: id,
        name: this.obligationType.permissions.find(
          (x) => x.representativeId === id
        ).representative,
      };
    });
    return representatives;
  }

  canBeSubmitted(form: NgForm) {
    if (!form.dirty) {
      return false;
    }

    if (!this.model.dateTime) {
      return false;
    }

    if (!this.model.permissionId) {
      return false;
    }

    if (!(this.model.amount > 0)) {
      return false;
    }

    if (this.model.lines.length === 0) {
      return false;
    }

    if (this.getTotalPercentage() !== 100) {
      return false;
    }

    if (this.getTotal() !== this.model.amount) {
      return false;
    }

    if (!this.model.personId) {
      return false;
    }

    return true;
  }

  updateAmount(line) {
    line.amount = parseFloat(
      ((line.percentage / 100) * this.model.amount).toFixed(2)
    );
  }

  updatePercentage(line) {
    line.percentage = parseFloat(
      ((line.amount / this.model.amount) * 100).toFixed(2)
    );
  }

  updateDivisions() {
    if (!(this.model.amount > 0)) {
      this.model.amount = 1;
    }

    if (this.model.lines && this.model.lines.length > 0) {
      for (const line of this.model.lines) {
        this.updateAmount(line);
      }
    }
  }

  getTotalPercentage() {
    return this.model.lines.reduce((x, y) => x + y.percentage, 0);
  }

  getTotal() {
    return this.model.lines.reduce((x, y) => x + y.amount, 0);
  }

  print() {
    this.receiptService.printReceipt(
      this.id,
      this.model.transactionTypeId,
      true
    );
  }
}
