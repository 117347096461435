import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-payment-accounts-select",
  templateUrl: "./payment-accounts-select.component.html",
  styleUrls: ["./payment-accounts-select.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentAccountsSelectComponent),
      multi: true,
    },
  ],
})
export class PaymentAccountsSelectComponent implements ControlValueAccessor {
  @Input() showAddOption = false;
  @Input() caption;
  @Input() selectDefault = true;
  @Input() value: number;
  @Output() valueChange: EventEmitter<number> = new EventEmitter();

  disabled = false;
  accounts;

  constructor(private httpClient: HttpClient) {
    this.httpClient
      .get("api/chartaccounts/payment-accounts")
      .subscribe((response) => {
        this.accounts = response;

        if (!this.value && this.selectDefault) {
          this.valueChange.emit(this.accounts[0].value);
        }
      });
  }

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change(newValue) {
    this.valueChange.emit(newValue);
    this.onModelChange(newValue);
  }
}
